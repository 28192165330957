@charset "utf-8";

// ------------------------------ //
// 変数宣言
// ------------------------------ //
// margin, padding
$space-xxl: 4rem;
$space-xl: 3.4rem;
$space-l: 2.4rem;
$space-m: 2rem;
$space-s: 1.6rem;
$space-xs: 1rem;
$space-xxs: .4rem;

// color
$color-key-red: #bf325e;
$color-key-green: #00aba2;
$color-key-brown: #2e2210;

// background
$color-bg-body: #ffffff;
$color-header: $color-key-green;
$color-footer: $color-key-brown;

// text
$color-text-black: $color-key-brown;
$color-text-black-light: #877c6b;

// tag
$color-tag-orange: $color-key-red;
$color-tag-teal: $color-key-green;

// form
$color-hover: $color-key-green;
$color-focus: $color-key-green;
$color-required: $color-key-red;
$color-optional: $color-key-green;
$color-success: $color-key-green;
$color-error: $color-key-red;
$color-submit: $color-key-red;

// border
$color-border-dashed: $color-text-black-light;
$color-border-under-section: $color-key-red;

// radius
$radius-round: 50%;
$radius-s: .6rem;
$radius-xs: .3rem;

// ------------------------------ //
// mixin 
// ------------------------------ //
// media query
// see here for usege: <https://www.nxworld.net/tips/sass-media-queries-mixin.html>
$mq-breakpoints: ( "xsmall": 320px, "small": 600px, "medium": 768px, "large": 1024px, "xlarge": 1280px);
@mixin mq($breakpoint: medium, $rule: min, $subtraction: false) {
  $breakpoint: map_get($mq-breakpoints, $breakpoint);
  @if $rule==max and $subtraction {
    $breakpoint: $breakpoint - 1;
  }
  @media screen and (#{$rule}-width: $breakpoint) {
    @content;
  }
}

// clearfix
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: block;
    height: 0;
  }
}

// FontAwesome
@mixin faSetcionTitleIcon($iconunicode) {
  &::before {
    content: $iconunicode;
    position: absolute;
    top: 0;
    left: 45%;
    width: 10%;
    color: $color-key-red;
    font-family: FontAwesome;
    font-size: 2rem;
  }
}

// ------------------------------ //
// styling
// ------------------------------ //
*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  // 16px * 62.5%  = 10px (1rem)
  font-size: 62.5%;
}

body {
  background-color: $color-bg-body;
  color: $color-text-black;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "ヒラギノ角ゴ ProN W3", Meiryo, sans-serif;
  font-size: 1.4rem;
  line-height: 1.75;
}

.siteHeader {
  &__navbtn {
    display: block;
    position: fixed;
    z-index: 1;
    bottom: $space-xl;
    right: $space-xs;
    width: 4rem;
    height: 4rem;
    background-color: rgba(0, 171, 162, .8);
    border-radius: $radius-round;
    text-align: center;
    cursor: pointer;
  }

  &__nav {
    // 吹き出しの土台
    // ref. <https://qiita.com/mpyw/items/6fa3965271f7d7f42be1>
    display: block;
    position: fixed;
    z-index: 1;
    bottom: $space-l * 4;
    right: $space-xs;
    background-color: #fafafa;
    border-radius: $radius-s;
    padding: $space-xs;
    -webkit-box-shadow: 0 0 .5rem 0 #dddddd;
    box-shadow: 0 0 .5rem 0 #dddddd;
    -webkit-transform: translateX(100vw);
    transform: translateX(100vw);
    -webkit-transition: 500ms;
    transition: 500ms;

    &::before {
      // 吹き出しのひし形部（四角形を回転、変形）
      content: '';
      position: absolute;
      z-index: 1;
      bottom: -1rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      background-color: #fafafa;
      -webkit-box-shadow: 0 0 .5rem 0 #dddddd;
      box-shadow: 0 0 .5rem 0 #dddddd;
      -webkit-transform: rotate(45deg) skew(20deg, 20deg);
      transform: rotate(45deg) skew(10deg, 10deg);
    }

    &::after {
      // 土台とひし形の重なりを隠す層
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fafafa;
      border-radius: $radius-s;
    }

    &__list {
      position: relative;
      z-index: 3;
      list-style: none;
      margin: 0;
      padding: 0;

      &__item {
        font-size: 1.4rem;
        text-align: right;

        &__a {
          position: relative;
          display: block;
          color: $color-text-black;
          text-decoration: none;
          padding: 1rem;
          width: 100%;
          border-radius: $radius-s;
          -webkit-tap-highlight-color: rgba(191, 50, 94, .1);

          &:hover {
            background-color: rgba(191, 50, 94, .1);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__title {
    color: #ffffff;
    background-color: $color-key-green;
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: .4rem;
    padding: $space-xs $space-s;
    margin: 0;

    &--small {
      color: inherit;
      font-size: 1.4rem;
    }
  }

  &__textbox {
    position: relative;
    margin: $space-s 0 0;
    text-align: center;

    &__text {
      position: absolute;
      width: 100%;
      top: 20%;
      right: 0;
      margin: 0;
      color: $color-text-black;
      font-size: 1.6rem;
      font-weight: normal;
      line-height: 2;
    }

    &__bg {
      width: 100%;
      margin: 0;
      color: rgba(46, 34, 16, .1);
      font-size: 6.2rem;
      line-height: 1.2;
    }
  }
}

.jsActive {
  color: $color-key-green;
  font-weight: bold;
  background-color: rgba(0, 171, 162, .1);
}

.main {
  padding: 0 $space-s;
}

.sectionHeader {
  &__title {
    position: relative;
    color: $color-text-black;
    font-size: 1.8rem;
    text-align: center;
    margin: $space-l 0;
    padding-top: $space-xl;
  }

  &__text {
    font-size: 1.6rem;
    text-align: left;
  }
}

.sectionContent,
.sectionHeader {
  margin: 0 auto;
  max-width: 980px;
}

.sectionContent {
  padding: $space-s;
  border-radius: $radius-s;
  background-color: #f8f2e6;
}

.news__list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    border-bottom: .1rem dashed $color-border-dashed;

    &:first-child {
      border-top: .1rem dashed $color-border-dashed;
    }

    &__date,
    &__text {
      margin: $space-xs 0;
    }

    &__date {
      color: $color-key-green;
      font-size: 1.2rem;
      font-weight: bold;
    }

    &__text {
      font-size: 1.4rem;

      &__list {
        margin: 0 0 0 $space-l;
        padding: 0;
        list-style: disc outside;
        font-size: 1.3rem;
      }
    }
  }
}

.card {
  background-color: #ffffff;
  border: 10px solid #ffffff;
  border-radius: $radius-s;
  margin-bottom: $space-m;

  &__thumb {
    &__img {
      vertical-align: middle;
      width: 100%;
      height: auto;
    }
  }

  &__caption {
    margin: $space-xs;

    &__title {
      color: $color-text-black;
      font-size: 1.4rem;
      margin: $space-s 0 $space-xs;

      &--suffix {
        padding-left: .2rem;
      }
    }

    &__text {
      font-size: 1.2rem;
      margin: $space-xs 0;
    }

    &__remarks {
      font-size: 1.2rem;
      margin: $space-xs 0;
    }
  }

  &__tagbox {
    margin: 0 $space-xs;
    text-align: left;

    &__title {
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
    }

    &__tag {
      display: inline-block;
      color: #ffffff;
      font-size: 1.1rem;
      font-weight: bold;
      padding: 0 .6rem;
      margin: 0;
      border-radius: $radius-xs;
      background-color: $color-text-black;

      &--orange {
        background-color: $color-tag-orange;
      }

      &--teal {
        background-color: $color-tag-teal;
      }
    }

    &__icon {
      font-size: 1.2rem;
      margin: 0;
      line-height: 1.2rem;
    }
  }
}

// 制作実績の2列表示
@include mq(medium, min, false) {
  .work .sectionContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .card {
    -ms-flex-preferred-size: 49%;
    flex-basis: 49%;

    &__thumb {
      &__img {
        -o-object-fit: contain;
        object-fit: contain;
        width: 100%;
        max-height: 300px;
      }
    }
  }
}

.profile {
  &__mainContent {
    text-align: center;
    margin: auto 0;

    &__img {
      border-radius: $radius-round;
      display: block;
      margin: 0 auto;
    }

    &__title {
      font-weight: bold;
    }

    &__text {
      margin-bottom: 0;
    }
  }

  &__subContent {
    &__title {
      font-weight: bold;
      margin: $space-l 0 $space-s;
      text-align: center;
    }
  }
}

// プロフィールの2列表示
@include mq(medium, min, false) {
  .profile {
    & .sectionContent {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    &__mainContent {
      -ms-flex-preferred-size: 46%;
      flex-basis: 46%;
    }

    &__subContent {
      -ms-flex-preferred-size: 53%;
      flex-basis: 53%;
    }

    &__subContent {
      &__title {
        margin-top: 0;
      }
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  &__row {
    &:nth-child(odd) {
      background-color: #ffffff;
    }

    &__title,
    &__text {
      padding: $space-xs $space-s;
    }

    &__title {
      width: 33%;
    }

    &__text {
      width: 67%;

      &__list {
        margin: 0 0 0 $space-m;
        padding: 0;
        list-style: disc outside;
        font-size: 1.3rem;

        &--small {
          font-size: 1.1rem;
        }
      }

      &--small {
        font-size: 1.2rem;
      }
    }
  }
}

.form {
  &__label {
    display: inline-block;
    vertical-align: middle;
    line-height: 2rem;
    font-size: 1.3rem;
    font-weight: bold;
  }

  &__text,
  &__textarea {
    font-size: 1.6rem;
    background-color: #ffffff;
    border: solid 2px transparent;
    border-radius: $radius-s;
    color: $color-text-black;
    line-height: 2.5rem;
    margin: $space-xs 0 $space-s;
    outline: 0; // focus時にborder-colorが隠れないようにoutline:0
    padding: .5rem 1rem;
    width: 100%;

    &:focus {
      border-color: $color-focus;
    }
  }

  &__textarea {
    resize: vertical;
  }

  &__submit {
    background-color: #ffffff;
    border: 0;
    border-left: solid .6rem $color-submit;
    -webkit-box-shadow: 0 .1rem .1rem rgba(0, 0, 0, .29);
    box-shadow: 0 .1rem .1rem rgba(0, 0, 0, .29);
    color: $color-submit;
    cursor: pointer;
    font-weight: bold;
    padding: $space-xs $space-s;
    text-decoration: none;
    width: 100%;

    &:hover {
      -webkit-box-shadow: 0 .1rem .1rem rgba(128, 128, 128, .1);
      box-shadow: 0 .1rem .1rem rgba(128, 128, 128, .1);
      -webkit-transform: translateY(.2rem);
      transform: translateY(.2rem);
    }
  }
}

.formResult {
  display: none;
  margin: $space-xl 0;

  &--success,
  &--error {
    background-color: white;
    border-radius: $radius-s;
    padding: $space-xs;
  }

  &--success {
    color: $color-success;
    border: solid 1px $color-success;
  }

  &--error {
    color: $color-error;
    border: solid 1px $color-error;
  }
}

.footer {
  background-color: $color-footer;
  color: rgba(255, 255, 255, .9);
  margin-top: $space-xl;
  padding: $space-l $space-s;
  text-align: center;

  &__copyright {
    font-size: 1.2rem;
    margin: 0;
  }
}

// --------------------
// FontAwesomeアイコン
// --------------------
// html <i>
.fa-close,
.fa-bars {
  color: #ffffff;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

// htmlでアイコン追加
.fa-asterisk {
  display: inline-block;
  line-height: 2rem;
  vertical-align: middle;
  margin-left: .2rem;
  color: $color-key-red;
  font-size: .8rem;
}

// cssでアイコン追加
.news .sectionHeader__title {
  @include faSetcionTitleIcon('\f09e');
}

.work .sectionHeader__title {
  @include faSetcionTitleIcon('\f040');
}

.profile .sectionHeader__title {
  @include faSetcionTitleIcon('\f007');
}

.about .sectionHeader__title {
  @include faSetcionTitleIcon('\f108');
}

.contact .sectionHeader__title {
  @include faSetcionTitleIcon('\f0e0');
}
